import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/shop-preview.css'
import { navigate } from 'gatsby';

const ShopPreview = ({ title, price, image, url }) => {
    return (
        <div className='shop-preview-container' onClick={() => navigate('/shop/' + url)}>
            <GatsbyImage image={image} className='shop-preview-image' />
            <h1 className='shop-preview-title'>{title}</h1><h1 className='shop-preview-price'>${price}</h1>
        </div>
    );
};

export default ShopPreview;