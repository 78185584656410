import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Draggable from "react-draggable"
import '../styles/shop.css'
import ShopPreview from '../components/ShopPreview';
import Dock from '../components/Dock';

const shop = ({ data }) => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="black" />
        <title>Shop - HYSG</title>
        <link rel="canonical" href="https://mysite.com/example" />
      </Helmet>
      <MenuBar />
      <div className='shop-container'>
        <Draggable><h1 className='title'>the love below</h1></Draggable>
        <div className='shop-previews-contanier'>
          {
            data.allShopifyProduct.nodes.map(node => (
              <ShopPreview title={node.title} price={node.priceRangeV2.maxVariantPrice.amount} image={node.featuredImage.gatsbyImageData} url={node.handle} />
            ))
          }
        </div>
      </div>
      <Dock />
    </main>
  );
};

export const query = graphql`
query ShopQuery {
  allShopifyProduct(sort: {order: ASC, fields: title}) {
    nodes {
      featuredImage {
        gatsbyImageData(width: 350)
      }
      title
      handle
      priceRangeV2 {
        maxVariantPrice {
          amount
        }
      }
    }
  }
}
`


export default shop;